import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { htmlSlidesType } from 'types/common';
import { IClassNotesLinks } from 'types/sliceTypes';

const initialState: IClassNotesLinks = {
  activeNotesHtmlData: {
    htmlLink: {
      teach: '',
      coach_basic: '',
      coach_intermediate: '',
      coach_advanced: '',
    },
    slide: 1,
    step: 0,
    currentState: 'teach',
    studentID: 0,
  },
};

export const classNotesState = createSlice({
  name: 'classNotesState',
  initialState,
  reducers: {
    setActiveNotesHtmlData: (state, action: PayloadAction<htmlSlidesType>) => {
      state.activeNotesHtmlData = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.activeNotesHtmlData.step = action.payload;
    },
    setSlide: (state, action: PayloadAction<number>) => {
      state.activeNotesHtmlData.slide = action.payload;
    },
  },
  selectors: {
    selectActiveNotesHtmlData: (state) => state?.activeNotesHtmlData,
  },
});

export const { setActiveNotesHtmlData, setStep, setSlide } = classNotesState.actions;

export const { selectActiveNotesHtmlData } = classNotesState.selectors;
