import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { DyteRecording } from '@dytesdk/recording-sdk';
import { useEffect, useRef } from 'react';

import { DyteParticipantsAudio, DyteSpinner } from '@dytesdk/react-ui-kit';
import { MeetingConfig } from 'types';
import useSocketConnection from 'hooks/useSocketConnection';
import Participants from './Participants';
import TimeComponent from './TimeComponent';
import AllContent from './AllContent';

export default function UIKitMeeting(props: {
  roomName: string;
  authToken: string;
  config: MeetingConfig;
  classId: string | null;
  apiBase: string | null;
}) {
  const { roomName, authToken, apiBase } = props;
  const [client, initClient] = useDyteClient();
  const elementRef = useRef(null);
  const socket = useSocketConnection({ classId: props.classId });
  // const [detectedEmotionsMap, setDetectedEmotionsMap] = useState<Record<string, any>>({});

  useEffect(() => {
    if (!authToken) {
      return;
    }
    async function setupDyteMeeting() {
      const recordingSDK = new DyteRecording({});
      const meetingObj = await initClient({
        roomName,
        authToken,
        defaults: {
          audio: false,
          video: false,
        },
        apiBase: apiBase ?? 'https://api.cluster.dyte.in',
      });
      if (meetingObj) {
        await recordingSDK.init(meetingObj);
      }
    }
    setupDyteMeeting();
  }, [authToken]);

  if (!client) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <DyteSpinner />
      </div>
    );
  }

  return (
    <DyteProvider value={client}>
      <div
        style={{
          backgroundColor: 'rgba(var(--dyte-colors-background-1000, 8 8 8))',
        }}
        ref={elementRef}
        className='grid h-screen grid-cols-12 gap-4'
      >
        <TimeComponent />
        {/* {MORPHCAST_ACTIVE === "true" ? (
        <Attention socket={socket} meeting={client} />
        ) : (
        <Emotion detectedEmotionsMap={detectedEmotionsMap} />
          )} */}
        <div className='col-span-9'>
          <AllContent socket={socket} />
        </div>
        <div className='col-span-3'>
          {/* setDetectedEmotionsMap={setDetectedEmotionsMap} pass this to participant when we want to show the emotion data in recording */}
          <Participants socket={socket} />
        </div>
      </div>
      <DyteParticipantsAudio meeting={client} />
    </DyteProvider>
  );
}
