import { useState, useEffect } from 'react';
import './timeComponent.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function TimeComponent() {
  const dateFormat = 'HH:mm:ss:SSS';
  const getCurrentISTStr = () => dayjs.tz(new Date(), 'Asia/Kolkata').format(dateFormat);

  const [currentTimeStr, setCurrentTime] = useState(getCurrentISTStr);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentISTStr);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='timeSpan'>
      <span>Current Time: {currentTimeStr}</span>
    </div>
  );
}
