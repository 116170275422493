import { Socket } from 'socket.io-client';
import { datadogLogs } from '@datadog/browser-logs';
import { useRef, useEffect } from 'react';

import { VAD_SOCKET_SERVER_URL } from '../configs/config';
import useMeeting from './useMeeting';

export const useStudentTalkTime = (socket: Socket | undefined) => {
  const { classId, joinedParticipants } = useMeeting();
  const connectedParticipants = useRef(new Map());

  useEffect(() => {
    const audioContext = new window.AudioContext();
    const sampleRate = audioContext.sampleRate;

    // Resume AudioContext if it's suspended
    if (audioContext.state === 'suspended') {
      audioContext.resume().then(() => {
        datadogLogs.logger.info(`AudioContext resumed`, {
          classId,
        });
      });
    }

    // Wait for a second to ensure audioTrack becomes available
    setTimeout(() => {
      audioContext.audioWorklet
        .addModule('/audio-processor.js')
        .then(() => {
          joinedParticipants.forEach((participant) => {
            const { presetName, customParticipantId, audioTrack } = participant;
            const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
            const participantId = `${userType}-${classId}-${customParticipantId?.split('-').pop()}`;

            // Create WebSocket for the participant if not already connected
            if (!connectedParticipants.current.has(participantId)) {
              const ws = createWebSocket(participantId, sampleRate);
              connectedParticipants.current.set(participantId, ws);
            }

            // Process audio track if available
            if (audioTrack) {
              processAudioTrack(participantId, audioTrack, audioContext);
            }
          });
        })
        .catch((error) => {
          datadogLogs.logger.error(`Error loading audio processor module: ${error}`, {
            classId,
            error,
          });
        });
    }, 1000);

    return () => {
      // Cleanup AudioContext on unmount
      audioContext.close();
    };
  }, [joinedParticipants.length]);

  // Creates a WebSocket connection for the participant with the sample rate
  const createWebSocket = (participantId: string, sampleRate: number) => {
    const ws = new WebSocket(`${VAD_SOCKET_SERVER_URL}/${participantId}/${sampleRate}`);

    ws.onopen = () => {
      datadogLogs.logger.info(
        `Connected to WebSocket for ${participantId} with sample rate ${sampleRate}`,
        {
          classId,
          participantId,
        },
      );
      datadogLogs.logger.info(
        `Current connected participants: ${Array.from(connectedParticipants.current.keys())}`,
        {
          classId,
          connectedParticipants,
        },
      );
    };

    ws.onclose = () => {
      datadogLogs.logger.info(`WebSocket closed for ${participantId}`, {
        classId,
        participantId,
      });
      connectedParticipants.current.delete(participantId);
      datadogLogs.logger.info(
        `Current connected participants: ${Array.from(connectedParticipants.current.keys())}`,
        {
          classId,
          connectedParticipants,
        },
      );
    };

    ws.onmessage = (message) => {
      let parsedMessage;
      try {
        parsedMessage = JSON.parse(message.data);
        if (socket) {
          socket?.emit('talkTimeData', {
            data: parsedMessage,
          });
          return () => {
            socket?.removeListener('talkTimeData');
          };
        }
      } catch (error) {
        datadogLogs.logger.error(`Failed to parse WebSocket message: ${error}`, {
          classId,
          error,
        });
        return;
      }
    };

    return ws;
  };

  // Processes the audio track for a specific participant
  const processAudioTrack = (
    participantId: string,
    audioTrack: MediaStreamTrack,
    audioContext: AudioContext,
  ) => {
    const mediaStream = new MediaStream([audioTrack]);
    const source = audioContext.createMediaStreamSource(mediaStream);

    const audioProcessor = new AudioWorkletNode(audioContext, 'audio-processor');

    // Handle processed audio data
    audioProcessor.port.onmessage = (event) => {
      const audioDataArray = event.data;
      sendAudioToAPI(audioDataArray, participantId);
    };

    source.connect(audioProcessor);
  };

  // Sends the processed audio data to the server via WebSocket
  const sendAudioToAPI = (data: number[], participantId: string) => {
    const ws = connectedParticipants.current.get(participantId);
    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ audio_data: data }));
    } else {
      datadogLogs.logger.error(`WebSocket for ${participantId} is not open or undefined`, {
        classId,
      });
    }
  };
};
