import { datadogLogs } from '@datadog/browser-logs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import Meeting from './pages/RecordingPage';
import reportWebVitals from './reportWebVitals';
import packageJson from '../package.json';
import { EMOTION_API_KEY, EMOTION_USERNAME } from './configs/config';
import { PersistGate } from 'redux-persist/integration/react';
import { persister, store } from './store';

window.triggerDyteRecording = true;

function getServiceNameFromEnv() {
  switch (import.meta.env.VITE_APP_MODE) {
    case 'dev':
      return 'dev-recording-app';
    case 'stage':
      return 'stage-recording-app';
    case 'prod':
      return 'recording-app';
    default:
      return 'dev-recording-app';
  }
}

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: getServiceNameFromEnv(),
  env: import.meta.env.VITE_APP_MODE,
  version: packageJson.version,
});

(async function () {
  try {
    await window.facialEye.init_fcet(EMOTION_USERNAME, EMOTION_API_KEY);
  } catch (error) {
    datadogLogs.logger.error(`🚀 ~ setTimeout ~ error: ${error}`, {
      error,
    });
  }
})();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter>
        <Routes>
          <Route path='/meeting/stage/:roomName' element={<Meeting />} />
          <Route path='/' element={<Meeting />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
