import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { PRESET_TYPE } from 'configs/meeting';
import { useSearchParams } from 'react-router-dom';

const useMeeting = () => {
  const [searchParams] = useSearchParams();

  // Getting the self participant
  const self = useDyteSelector((meeting) => meeting?.self);

  // Getting the screen share host
  const screenShareHost = useDyteSelector((meeting) => {
    return meeting?.self?.screenShareEnabled
      ? meeting?.self
      : meeting?.participants?.joined?.toArray()?.find((p) => p?.screenShareEnabled);
  });

  // Checking if screen share is enabled
  const screenShareEnabled = useDyteSelector((meeting) => {
    return (
      !!meeting?.participants?.joined
        ?.toArray()
        ?.find((participant) => participant?.screenShareEnabled) ||
      meeting?.self?.screenShareEnabled
    );
  });

  // Active plugins
  const activePlugins = useDyteSelector((meeting) => meeting?.plugins?.active?.toArray());

  //All plugins
  const allPlugins = useDyteSelector((meeting) => meeting?.plugins?.all?.toArray());

  const socketUser = self.presetName === PRESET_TYPE.VIEWER ? PRESET_TYPE.VIEWER : 'recorder';

  const classId = searchParams.get('classId') || '';

  const { meeting } = useDyteMeeting();

  const joinedParticipants = useDyteSelector((m) => m?.participants?.joined?.toArray());

  return {
    self,
    screenShareHost,
    screenShareEnabled,
    activePlugins,
    socketUser,
    classId,
    meeting,
    allPlugins,
    joinedParticipants,
  };
};

export default useMeeting;
