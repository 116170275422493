import { HTMLAttributes, useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';

import { DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { NODE_ENV } from '../configs/config';
import { useMeetingStore } from '../lib/meeting-store';
import { detectEmotionsFromStream } from '../utils';
import { useStudentTalkTime } from '../hooks/useStudentTalkTime';
import useMeeting from 'hooks/useMeeting';
import { PRESET_TYPE } from 'configs/meeting';

export default function ActiveSpeaker(
  props: Omit<HTMLAttributes<HTMLDyteParticipantTileElement>, 'style'> & {
    isSmall?: true;
    // setDetectedEmotionsMap: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    socket: Socket | undefined;
  },
) {
  const { isSmall, socket } = props;

  const processedStudentsRef = useRef(new Map());
  const { meeting, classId, joinedParticipants, socketUser } = useMeeting();

  const [size, states] = useMeetingStore((s) => [s.size, s.states]);

  if (socketUser !== PRESET_TYPE.VIEWER) {
    useStudentTalkTime(socket);
  }

  useEffect(() => {
    joinedParticipants?.forEach((participant) => {
      if (
        participant?.videoTrack &&
        !processedStudentsRef.current.has(participant.id) &&
        NODE_ENV === 'prod' &&
        participant.presetName !== PRESET_TYPE.VIEWER
      ) {
        processedStudentsRef.current.set(participant.id, true);
        const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
        detectEmotionsFromStream(
          participant.videoTrack,
          // setDetectedEmotionsMap,
          socket,
          userType,
          participant.customParticipantId?.split('-').pop(),
          String(classId),
        );
      }
    });
  }, [joinedParticipants]);

  return (
    <div className='flex flex-col justify-center h-screen px-6 py-6'>
      {joinedParticipants?.map((participant) => (
        <DyteParticipantTile
          key={participant.id}
          participant={participant}
          meeting={meeting}
          size={isSmall ? 'lg' : size}
          states={states}
          {...props}
          className='my-2'
        />
      ))}
    </div>
  );
}
